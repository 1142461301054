import { useEffect, useState } from 'react';
import { StatelessDropDownButtonProps, DropDownOption } from '../UtilityProps';
import { classNames } from '~/components/utils';
import Popover from '../Popover';

export default function StatelessDropDownButton({
	label,
	options,
	className,
	activeOptions,
	sendSelections,
	showActive = false,
	clearValues = [],
}: StatelessDropDownButtonProps) {
	const handleClick = (selection: DropDownOption) => {
		if (activeOptions.indexOf(selection.value + '') != -1) {
			const newOptions = activeOptions.filter((option) => option !== selection.value + '');
			sendSelections(newOptions);
		} else {
			sendSelections(activeOptions.concat([selection.value + '']));
		}
	};

	const clearSelections = () => {
		sendSelections(clearValues);
	};

	const [selectedTextLabel, setSelectedTextLabel] = useState<string | null>(null);

	// if showActive is selected, render values to string to show instead of placeholder
	useEffect(() => {
		if (showActive) {
			// selected opts is a string array, even if the values are indices they get
			// converted to strings on select, so check active option values against stringified select option values

			const selectedOpts = options.filter((option) => activeOptions.includes(String(option.value)));

			const activeOptsFullString = selectedOpts.map((obj) => obj.text).join(', ');
			setSelectedTextLabel(activeOptsFullString);
		}
	}, [activeOptions, options, showActive]);

	return (
		<Popover>
			<Popover.Button
				icon="chevron-down"
				globalClassName={classNames(
					className ? className : 'bg-gray-200 text-black hover:bg-gray-300 border border-transparent',
					`focus:ring-orange-50 inline-flex items-center rounded-md  px-4 py-2 text-sm font-medium ${
						activeOptions && activeOptions.length > 0 ? 'border-primary-dark' : ''
					}`
				)}
			>
				<span className="truncate w-full">{selectedTextLabel ? selectedTextLabel : label}</span>
			</Popover.Button>
			<Popover.Panel className="absolute bg-white rounded-md shadow-lg mt-3 w-56 z-10 px-4 sm:px-0">
				<div className="z-10 flex flex-col border-2 border-glazier-blue-700 rounded-md">
					<div className="max-h-60 flex flex-col overflow-y-scroll">
						{options.map((item) => {
							const isActive = activeOptions.indexOf(item.value + '') != -1;
							return (
								<span
									key={item.value}
									onClick={() => handleClick(item)}
									className={classNames(
										isActive ? ' bg-glazier-blue-700 text-gray-100 ' : 'hover:bg-gray-200 text-gray-900',
										'z-0 py-2 px-3 transition duration-150 ease-in-out cursor-pointer'
									)}
								>
									<p className="text-sm font-medium">{item.text}</p>
								</span>
							);
						})}
					</div>
					<Popover.Button
						onClick={clearSelections}
						globalClassName="z-0 py-2 px-4 transition duration-150 ease-in-out cursor-pointer bg-glazier-blue-700 hover:bg-glazier-blue-500"
					>
						<p className="text-sm font-medium text-gray-100">Clear All {label}</p>
					</Popover.Button>
				</div>
			</Popover.Panel>
		</Popover>
	);
}
