/*
Throttle/debounce from start. 

If `!` is a click, `*` is an action, and `.` is the timeout: `!*..!.....`
*/
export function throttle(func: (...args: any[]) => void, timeout = 1000) {
	let timer: number | undefined;
	return (...args: any[]) => {
		if (timer == undefined) {
			func(...args);
		}
		//Restart the clock on each press
		window.clearTimeout(timer);
		timer = window.setTimeout(() => {
			timer = undefined;
		}, timeout);
	};
}

/*
Debounce from the end. 

If `!` is a click, `*` is an action, and `.` is the timeout: `!..!.....*`
*/
export function debounce(func: (...args: any[]) => void, timeout = 1000) {
	let timer: number | undefined;
	return (...args: any[]) => {
		//Restart the clock on each press
		window.clearTimeout(timer);
		timer = window.setTimeout(() => {
			func(...args);
			timer = undefined;
		}, timeout);
	};
}
