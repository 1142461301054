import { throttle } from '@utils/functional';
import { trpc, RouterInput, RouterOutput } from '@utils/trpc';
import { useCallback } from 'react';

interface InfinitePage {
	items: unknown[];
	nextCursor?: number;
}

export type routerName = keyof RouterInput;
export type routerMethod<router extends routerName> = keyof RouterInput[router];

export type inferInfiniteQueryOutput<name extends routerName, method extends routerMethod<name>> = Exclude<RouterOutput[name][method], undefined>;

/**
 *
 * @param name The name of the router
 * @param method The name of the method on the router
 * @param input Input for the function
 * @param options Options for the query. They are the same as any useQuery option as defined in the TanStack docs.
 * @returns query A UseQueryResult for the endpoint
 * @returns data All data loaded so far on every page.
 * @returns fetchNextPage Callback to fetch the next page
 * @returns isLoading True if the query is loading the first page.
 * @returns isError True if the query encountered an error.
 * @returns isFetching True if the query is loading any page, including the first.
 */
function useInfinite<TRouter extends routerName, TMethod extends routerMethod<TRouter>>(
	name: TRouter,
	method: TMethod,
	input: RouterInput[TRouter][TMethod],
	options: { enabled?: boolean } = { enabled: true }
) {
	// @ts-expect-error Too much work to figure out which queries are infinite.
	const query = trpc[name][method].useInfiniteQuery(input, {
		getNextPageParam: (lastPage?: InfinitePage) => lastPage && lastPage.nextCursor,
		...options,
	});

	const data: inferInfiniteQueryOutput<typeof name, typeof method>['items'] =
		query.isSuccess && query.data ? query.data.pages.flatMap((p?: InfinitePage) => (p ? p.items : [])) : [];

	const fetchNextPage = throttle(
		useCallback(() => {
			if (!query.isFetching && query.hasNextPage) {
				query.fetchNextPage();
			}
		}, [query])
	);

	return {
		query,
		data,
		fetchNextPage,
		hasNextPage: query.hasNextPage,
		isLoading: query.isLoading, // Is this the initial load?
		isError: query.isError,
		isFetching: query.isFetching, // Are we getting something from the server?
		isSuccess: query.isSuccess,
	};
}

export default useInfinite;
