import { Dispatch, SetStateAction } from 'react';

export type MinLengthArray<MinLength extends number, ItemType> = ItemType[] & {
	0: MinLength extends 0 ? never : ItemType;
} & {
	length: MinLength;
};

export type ContextUseState<T> = [T, Dispatch<SetStateAction<T>>];

export const CUSTOM_FORM = {
	ADD_OWN_TYPE: 'Add your own type',
} as const;

export function isNullish<T>(argument: T | undefined | null): argument is undefined | null {
	return argument === null || argument === undefined;
}

export function isNotNullish<T>(argument: T | undefined | null): argument is T {
	return argument !== null && argument !== undefined;
}

export function isNotNullishOrNaN(argument: number | undefined | null): argument is number {
	return argument !== null && argument !== undefined && !isNaN(argument);
}
